<script setup lang="ts">
const requestUrl = useRequestURL()

let action: String | undefined

if (import.meta.server) {
  try {
    action = requestUrl.pathname.split('/')[2] ?? ''
  } catch {}
} else {
  action = 'client'
}
</script>

<template>
  <os-ssr-helper-home v-if="action === ''" />
  <os-ssr-helper-deal v-if="action === 'deals'" />
</template>
