import messages from '~/messages/messages'

export default (
  key: string,
  defaultMessage?: string,
  currentLocale?: string,
): string | null => {
  currentLocale = currentLocale ?? useCurrentLocale()

  // @ts-ignore
  return messages[currentLocale][key] ?? defaultMessage
}
