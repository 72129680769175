export function isApp(): boolean {
  // @ts-ignore
  return 'Capacitor' in globalThis && globalThis.Capacitor.isNativePlatform()
}

export function isAndroid(): boolean {
  // @ts-ignore
  return isApp() && globalThis.Capacitor.platform === 'android'
}

export function openInBrowser(
  url: string,
  openInNewWindow: boolean = false,
): void {
  if (isApp()) {
    // Navigate in the app
    if (
      false &&
      url.includes('appleid.apple.com') // Apple ID (Automatically overlays the app)
    ) {
      globalThis.location.href = url
    }

    // Use the in-app browser
    else {
      //@ts-ignore
      Capacitor.Plugins.Browser.open({ url: url, presentationStyle: 'popover' })
    }
  } else {
    if (openInNewWindow) {
      window.open(url, '_blank')
    } else {
      globalThis.location.href = url
    }
  }
}

export async function promptForPushNotifications(): Promise<void> {
  if (isApp()) {
    //@ts-ignore
    await Capacitor.Plugins.Preferences.set({
      key: 'prompt_for_notifications',
      value: 'true',
    })

    //@ts-ignore
    const home = await Capacitor.Plugins.Preferences.get({ key: 'home' })

    globalThis.location.href = home.value
  }
}

export function initApp(): void {
  if (isApp()) {
    // hide splash screen
    //@ts-ignore
    Capacitor.Plugins.SplashScreen.hide()

    // enable animations
    //@ts-ignore
    Ionic.config.set('animated', true)

    // increase safe zone
    document.body.style.setProperty(
      '--ion-safe-area-top',
      'env(safe-area-inset-top)',
    )
    document.body.style.setProperty(
      '--ion-safe-area-bottom',
      'env(safe-area-inset-bottom)',
    )

    // store the current origin so when the app is restarted, this origin is loaded again
    const currentLocale = useCurrentLocale()
    //@ts-ignore
    Capacitor.Plugins.Preferences.set({
      key: 'origin',
      value: globalThis.location.origin + '/' + currentLocale + '/',
    })

    //@ts-ignore
    Capacitor.Plugins.Preferences.set({
      key: 'language',
      value: currentLocale,
    })

    const authentication = useAuthentication()
    //@ts-ignore
    Capacitor.Plugins.Preferences.set({
      key: 'user',
      value: 'user_id_' + (authentication.getUser()?.id ?? 'unknown'),
    })

    // handle deeplinks
    //@ts-ignore
    const App = globalThis.Capacitor.Plugins.App

    function handleDeepLink(url: string, isLaunchUrl: boolean = false) {
      // Only handle launch urls once
      if (isLaunchUrl) {
        if (!globalThis.sessionStorage.getItem('deepLink-' + url)) {
          globalThis.sessionStorage.setItem('deepLink-' + url, 'true')
        } else {
          return
        }
      }

      // Handle deep links
      if (url.startsWith('outspot://')) {
        const deepLocation = new URL(
          document.location.origin +
            '/' +
            url
              .replace('outspot:///', '')
              .replace('outspot://', '')
              .replace('outspot:/', '')
              .trim(),
        )

        window.location.href = deepLocation.href ?? '/'

        try {
          //@ts-ignore
          Capacitor.Plugins.Browser.close()
        } catch (error) {
          //
        }
      } else {
        // Handle other deep links
        window.location.href = url
      }
    }

    App.addListener('appUrlOpen', (data: any) => {
      const url = data.url
      handleDeepLink(url)
    })

    App.getLaunchUrl().then((data: any) => {
      if (data && data.url) {
        const url = data.url
        handleDeepLink(url, true)
      }
    })
  }
}
