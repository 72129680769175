<script setup lang="ts">
const requestUrl = useRequestURL()

const language = requestUrl.pathname.split('/')[1]
const slug =
  requestUrl.pathname.split('/')[3] !== 'test'
    ? requestUrl.pathname.split('/')[3]
    : requestUrl.pathname.split('/')[4]

let deal: Deal | undefined

const shouldPrerenderBody =
  requestUrl.pathname.endsWith(slug) || requestUrl.pathname.endsWith(slug + '/')

function stripTags(html?: string): string {
  try {
    return (html ?? '').replace(/<[^>]*>?/gm, '')
  } catch (error) {
    console.error(error)
    return ''
  }
}

try {
  const path =
    'https://api.outspot.be/v2/public/deals/slug/' +
    slug +
    '?language=' +
    language

  const { data } = await useAsyncData(path.split('?')[0], () => $fetch(path))

  // @ts-ignore
  deal = data.value?.data as Deal

  useSeoMeta({
    title: deal ? stripTags(deal.shortName) + ' — Outspot' : 'Outspot',
    ogTitle: stripTags(deal.shortName),
    description: stripTags(deal.description),
    ogDescription: stripTags(deal?.description).substring(0, 200) + '…',
    ogImage: deal?.images[0]?.xl,
    ogUrl: requestUrl.href,
    twitterCard: 'summary_large_image',
    twitterTitle: stripTags(deal?.shortName),
    twitterDescription: stripTags(deal?.description).substring(0, 200) + '…',
    twitterImage: deal?.images[0]?.xl,
  })
} catch (error) {}
</script>

<template>
  <div class="prerendered" v-if="deal && shouldPrerenderBody">
    <h1>{{ deal?.shortName }}</h1>
    <h2>{{ deal?.name }}</h2>
    <div v-html="deal?.highlights"></div>
    <div v-html="deal?.description"></div>
    <div v-html="deal?.practical"></div>
    <img v-for="image in deal?.images" :src="image.xl" :alt="deal?.shortName" />
  </div>
</template>

<style scoped>
.prerendered {
  position: fixed;
  top: -9999px;
  left: -9999px;
  width: 100px;
  height: 100px;
}
</style>
