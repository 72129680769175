import { defineNuxtPlugin } from '#app'
import { initializeApp } from 'firebase/app'
import { getAnalytics, isSupported, logEvent } from 'firebase/analytics'
import { FirebaseAnalytics } from '@capacitor-firebase/analytics'

export interface AnalyticsProvider {
  logEvent: (eventName: string, params?: Record<string, any>) => Promise<void>
}

export default defineNuxtPlugin(async (nuxtApp) => {
  try {
    const firebaseConfig = {
      apiKey: 'AIzaSyB70h0G-6TE95dwf9QkVpzK0BZbf95p3Tw',
      authDomain: 'outspot-app.firebaseapp.com',
      projectId: 'outspot-app',
      storageBucket: 'outspot-app.firebasestorage.app',
      messagingSenderId: '891266074231',
      appId: '1:891266074231:web:12667366ba02b5ca42a872',
      measurementId: 'G-P9G2RNSKHS',
    }

    const firebaseApp = initializeApp(firebaseConfig)
    let analytics

    if (isApp()) {
      analytics = {
        logEvent: async (eventName: string, params: any = {}) => {
          await FirebaseAnalytics.logEvent({ name: eventName, params })
        },
      }
    } else if (await isSupported()) {
      const webAnalytics = getAnalytics(firebaseApp)
      analytics = {
        logEvent: async (eventName: string, params: any = {}) => {
          await logEvent(webAnalytics, eventName, params)
        },
      }
    } else {
      analytics = {
        logEvent: () => {},
      }
    }

    nuxtApp.provide('firebaseAnalytics', analytics)
  } catch (error) {
    console.error('Error initializing Firebase', error)
  }
})
