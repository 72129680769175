<script setup lang="ts">
const requestUrl = useRequestURL()

const language = requestUrl.pathname.split('/')[1]

let deals: DealList | undefined

function stripTags(html?: string): string {
  try {
    return (html ?? '').replace(/<[^>]*>?/gm, '')
  } catch (error) {
    console.error(error)
    return ''
  }
}

try {
  const path = 'https://api.outspot.be/v2/public/deals?language=' + language

  const { data } = await useAsyncData(path.split('?')[0], () => $fetch(path))

  // @ts-ignore
  deals = data.value?.data as DealList

  useSeoMeta({
    title: 'Outspot',
    ogTitle: 'Outspot',
    ogImage: 'https://www.outspot.be/images/outspot-logo.png',
    ogUrl: requestUrl.href,
  })
} catch (error) {}
</script>

<template>
  <div class="prerendered" v-if="deals">
    <h1>Outspot</h1>
    <template v-for="(sectionDeals, sectionName) in deals">
      <h2>{{ $t('home.' + sectionName) }}</h2>
      <ul>
        <li v-for="deal in sectionDeals">
          <h3>
            <a :href="`/${language}/deals/${deal.slug}`">{{ deal.name }}</a>
          </h3>
          <a :href="`/${language}/deals/${deal.slug}`">
            <img :src="deal.images[0].xl" :alt="deal.name" />
          </a>
        </li>
      </ul>
    </template>
  </div>
</template>

<style scoped>
.prerendered {
  position: fixed;
  top: -9999px;
  left: -9999px;
  width: 100px;
  height: 100px;
}
</style>
